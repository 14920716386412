<template>
  <Page mode="jacket">
    <h1>{{ labels.YourPersonalCitizenAccount }}</h1>
    <p v-html="labels.Introduction"></p>
    <div class="vgs-form-indent1">
    <div class="new-form-iframe">
      <iframe width="350px" height="350px" :src="HandySignatureForm"></iframe>
    </div>
    </div>
    <div class="vgs-form-indent text-left">
      <RouteLink route="signup" icon="arrow-right" :before="labels.NoEGovernmentAccountYet" :text="labels.RegisterNow"></RouteLink>
      <RouteLink route="signin" icon="arrow-right" :text="labels.GoToSignIn"></RouteLink>
    </div>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import RouteLink from '@/structure/RouteLink.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'

export default {
  mixins: [vcm,vvm],
  name: 'SignInEID',
  components: {
    Page, RouteLink
  },
  data() {
    return {
      HandySignatureForm: 'https://eid.oesterreich.gv.at/auth/idp/profile/oidc/authorize?response_type=code&client_id=https%3A%2F%2Fkaerstin.at&redirect_uri=https%3A%2F%2Fkaerstin.at%2Fegp&scope=openid+profile+eid',
      labels: {
        YourPersonalCitizenAccount: this.get_translation('SignIn','YourPersonalCitizenAccount','label'),
        Introduction: this.get_translation('SignIn','Introduction','label'),
        NoEGovernmentAccountYet: this.get_translation('SignIn','NoEGovernmentAccountYet','label'),
        RegisterNow: this.get_translation('SignIn','RegisterNow','label'),
        EIDGoToSignIN: this.get_translation('SignIn','EIDGoToSignIN','label'),
        ForgotPassword: this.get_translation('SignIn','ForgotPassword','label'),
        GoToSignIn: this.get_translation('App','GoToSignIn','label'),
      }
    };
  },
  mounted: function () {
    this.$root.set_document_title('route','','')
  }
}

</script>

<style scoped>

h1 { font-size: 1.875rem; line-height: 2.563rem; color: #121212; }
p { font-size: 1rem; line-height: 1.625rem; color: #121212; }

.new-form-iframe iframe {
    width: auto;
    border: none;
    margin-top: 10px;
    padding: 20px 20px;
    border:1px solid #f2f2f2;
}
@media (max-width:991px){
  .new-form-iframe iframe {
    /* width:90%; */
    width: auto;
  }
}

</style>
